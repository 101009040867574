/**
 * Component: Modal
 */

export default class Modal {
    constructor(id, config) {
        this.ns = 'modal';
        this.$self = jQuery('#' + id + '.' + this.ns);
        this.options = this.$self.data('options');
        this.$overlay = this.$self.find('.' + this.ns + '__overlay');
        this.$closeBtn = this.$self.find('.' + this.ns + '__close');

        this.closeElements = [];
        this.closeElements.push(this.$overlay); // Close the modal if overlay is clicked
        this.closeElements.push(this.$closeBtn);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        if (this.options) {
            // Bind click event to trigger modal selector
            this.$modalOpenElement = jQuery(this.options.triggerModalOpenSelector);
            // Set autoOpen if exists in options object
            this.autoOpen = this.options.autoOpen;
            this.autoOpenFlag = this.options.autoOpenFlag;
        }

        if (this.$self) {
            this.init(config);
        }
    }

    init(config) {
        this.setOpenHandlers();
        this.setCloseHandlers();
        this.setCloseButtonState(this.$closeBtn, config);
        this.setOptionalCallbacks(config);

        if (this.autoOpen && this.autoOpenFlag) {
            this.openOnInit();
        }
    }

    openOnInit() {
        // If localStorage key has NOT been set yet, open modal.
        if (!window.localStorage.getItem(this.autoOpenFlag)) {
            this.openModal();
        }
    }

    setOpenHandlers() {
        if (this.$modalOpenElement) {
            this.$modalOpenElement.on('click', () => {
                this.openModal();
            });
        }
    }

    openModal() {
        $('body').addClass('no-scroll');
        console.log(this.$self);
        this.$self.addClass(this.ns + '--opened');

        if (this.openCallback) {
            this.openCallback();
        }
    }

    setCloseHandlers() {
        for (var i = 0; i < this.closeElements.length; i++) {
            var el = this.closeElements[i];
            if (el) {
                $(el).on('click', () => {
                    this.closeModal();
                });
            }
        }

        $(document).on('keydown', (e) => {
            var key = e.key || e.keyCode;
            if (
                key === 'Escape'
                    || key === 'Esc'
                    || key === 27
            ) {
                this.closeModal();
            }
        });
    }

    closeModal() {
        $('body').removeClass('no-scroll');
        this.$self.removeClass(this.ns + '--opened');

        if (this.closeCallback) {
            this.closeCallback();
        }

        if (this.autoOpen && this.autoOpenFlag) {
            window.localStorage.setItem(this.autoOpenFlag, true);
        }
    }

    setCloseButtonState($closeBtn, config) {
        if (config) {
            this.showCloseButton = config.showCloseButton;

            if (this.showCloseButton === true) {
                $closeBtn.show();
            } else {
                $closeBtn.hide();
            }
        }
    }

    setOptionalCallbacks(config) {
        if (config) {
            var openCb = config.openCallback;
            var closeCb = config.closeCallback;
            if (typeof openCb === 'function') {
                this.openCallback = openCb;
            }

            if (typeof closeCb === 'function') {
                this.closeCallback = closeCb;
            }
        }
    }
}
