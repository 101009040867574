/**
 * Arlo SFCC Static
 * Main entry point.
 */

/**
  * Global Modules
  */
import Modal from './components/Modal';

new Modal('region-selector-content');// eslint-disable-line no-new

/**
 * Handle Mobile Navigation
 */
import MobileNavigation from './components/MobileNavigation';
const mobile_nav = new MobileNavigation();

/**
 * Main Variables
 */

var body = document.querySelector('body');

/**
 * HEADER FUNCTIONALITY
 */
var header = document.querySelector('.header');
var miniCartHeader = document.querySelector('.minicart__header');
var headerBanner = document.querySelector('.header__banner');
var headerContainer = document.querySelector('.header__container');

if (headerBanner) {
    if (headerBanner.classList.contains('active')) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > headerBanner.offsetHeight) {
                headerContainer.classList.add('fixed');
                miniCartHeader.classList.add('scrolled');
            } else {
                headerContainer.classList.remove('fixed');
                miniCartHeader.classList.remove('scrolled');
            }
        });
    } else {
        headerContainer.classList.add('fixed');
    }
}

var hamburger = document.querySelector('.header__mobile-hamburger');

if (hamburger) {
    hamburger.addEventListener('click', () => {
        if (hamburger.classList.contains('active')) {
            hamburger.classList.remove('active');
            body.classList.remove('no-scroll');
            headerContainer.classList.remove('menu-open');
        } else {
            hamburger.classList.add('active');
            body.classList.add('no-scroll');
            headerContainer.classList.add('menu-open');
        }
        setTimeout(() => { mobile_nav.resetMobileNav(); }, 500);
    }, false);

    window.addEventListener('resize', () => {
        if (window.innerWidth >= 1200) {
            hamburger.classList.remove('active');
            body.classList.remove('no-scroll');
            headerContainer.classList.remove('menu-open');
        }
    });
}

/**
* CHECKOUT HEADER FUNCTIONALITY
*/
var expandOrderSummaryButton = document.querySelector('[data-expand-summary]');

if (expandOrderSummaryButton) {
    expandOrderSummaryButton.addEventListener('click', () => {
        jQuery('[data-order-summary-column]').slideToggle('slow');
        jQuery('[data-expand-arrow]').toggleClass('expanded');
    });
}

/**
 * FOOTER FUNCTIONALITY
 */
var newsletterInput = document.querySelector('.footer__newsletter-form input');
var newsletterButton = document.querySelector('.footer__newsletter-form button');
var newsletterDesktopPlaceholder = document.querySelector('.footer__newsletter-placeholder');

if (newsletterInput) {
    newsletterInput.addEventListener('input', () => {
        if (newsletterInput.value) {
            newsletterButton.disabled = false;
            newsletterDesktopPlaceholder.style.display = 'none';
        } else {
            newsletterButton.disabled = true;
            newsletterDesktopPlaceholder.style.display = '';
        }
    });
}

/**
 * MODAL/POPUP FUNCTIONALITY
 */
var modalOverlay = document.querySelector('.modal-overlay');
var searchIcon = document.querySelector('.item-search');
var searchBar = document.querySelector('.search-bar');
var cartIcon = document.querySelector('.item-cart');
var miniCart = document.querySelector('.minicart');
var searchBarClose = document.querySelector('.search-bar__close');
var miniCartClose = document.querySelector('.minicart__close');


/**
 * Removes classes to remove modal overlays.
 */
function closeOverlays() {
    body.classList.remove('no-scroll');
    modalOverlay.classList.remove('active');
    searchBar.classList.remove('active');
    miniCart.classList.remove('active');
    header.classList.remove('cart-open');
    headerBanner.classList.remove('cart-open');
}

/**
 * Search Icon click
 */
if (searchIcon) {
    searchIcon.addEventListener('click', (e) => {
        e.preventDefault();
        body.classList.add('no-scroll');
        modalOverlay.classList.add('active');
        searchBar.classList.add('active');
    });
}

/**
 * Search Bar Close Click
 */
if (searchBarClose) {
    searchBarClose.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Cart Icon click
 */
if (cartIcon) {
    cartIcon.addEventListener('click', (e) => {
        e.preventDefault();
        body.classList.add('no-scroll');
        modalOverlay.classList.add('active');
        miniCart.classList.add('active');
        header.classList.add('cart-open');
        headerBanner.classList.add('cart-open');
    });
}

/**
 * Mini Cart Close Click
 */
if (miniCartClose) {
    miniCartClose.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Modal Overlay Click
 */
if (miniCartClose) {
    modalOverlay.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Floating Labels for input fields
 */
var floatContainers = document.querySelectorAll('.form-float-container');

// add active class
var handleFocus = (e) => {
    var target = e.target;
    target.parentNode.classList.add('active');
    target.setAttribute('placeholder', (target.getAttribute('data-placeholder') || ''));
};

// remove active class
var handleBlur = (e) => {
    var target = e.target;

    if (!target.value) {
        target.parentNode.classList.remove('active');
    }

    target.removeAttribute('placeholder');
};

var bindEvents = (element) => {
    var floatField = element.querySelector('input') ? element.querySelector('input') : element.querySelector('textarea');
    floatField.addEventListener('focus', handleFocus);
    floatField.addEventListener('blur', handleBlur);
};

if (floatContainers.length > 0) {
    floatContainers.forEach((element) => {
        if (element.querySelector('select')) return;

        var inputElement = element.querySelector('input') ? element.querySelector('input') : element.querySelector('textarea');

        if (inputElement.value) {
            element.classList.add('active');
        }

        bindEvents(element);
    });
}

// Use select2 for selects

jQuery('.custom-select-dropdown').select2({
    minimumResultsForSearch: -1,
    dropdownPosition: 'below',
    width: '100%',
});

jQuery('.custom-select-dropdown-bluegray').select2({
    minimumResultsForSearch: -1,
    dropdownPosition: 'below',
    selectionCssClass: 'bluegray',
    dropdownCssClass: 'bluegray',
    width: '100%',
});

jQuery('.custom-select-dropdown-lighttint').select2({
    minimumResultsForSearch: -1,
    dropdownPosition: 'below',
    selectionCssClass: 'lighttint',
    dropdownCssClass: 'lighttint',
    width: '100%',
});

/**
 * Handle Navigation Dropdown
 */
$('.toggle-nav-dropdown').on('mouseenter', () => {
    $('.navigation-dropdown').addClass('show');
});
$('.header__nav-link:not(.toggle-nav-dropdown)').on('mouseenter', () => {
    $('.navigation-dropdown').removeClass('show');
});
$('.navigation-dropdown').on('mouseleave', () => {
    $('.navigation-dropdown').removeClass('show');
});

$('.header__container').on('mouseleave', () => {
    $('.navigation-dropdown').removeClass('show');
});

